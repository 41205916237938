import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { Constant } from 'app/config/constants';
import { DateTime } from 'luxon';
import moment from 'moment';
import { CustomDatePickerI18n } from './custom-date-picker-i18n';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatePickerI18n }]
})
/**
 * Custom date time picker component based on ng-bootstrap date picker & time picker
 */
export class CustomDatePickerComponent implements OnChanges {
  // @ViewChild('datepicker') datepicker: NgbDatepicker;
  // set to true when time is included
  @Input() public withTime: boolean;

  // set to true when in daily schedule pop up
  @Input() public width: String;

  // set to true when in daily schedule pop up
  @Input() public isClear: boolean;
  @Input() public isClearTime: boolean;

  // set to true when in dialog pop up
  @Input() public inDialog: boolean;

  // input date time string
  @Input() public currentDateTime: Date;

  /**
   * is valid
   */
  @Input() public isValid: boolean;

  /**
   * left
   */
  @Input() public left: String;

  /**
   * margin left
   */
  @Input() public marginLeft: String;

  /**
   * has border
   */
  @Input() public hasBorder: boolean;

  /**
   * is scroll top
   */
  @Input() public isScrollTop: boolean = false;

  /**
   * Placeholder
   */
  @Input() public placeholder: String;

  @Input() public time: any;

  @Input() public formatDate: string;
  @Input() public isTicketEditor: string;
  @Input() public isTicketManager: string;
  @Input() public listDate: any[];
  @Input() public isDisabled: boolean;
  /**
   * date display
   */
  public dateDisplay: string;

  // current date struct
  public currentDateStruct: NgbDateStruct;

  // current time struct
  public currentTimeStruct: NgbTimeStruct;

  // event emitted when date or time is changed
  @Output() public currentDateChange = new EventEmitter<String>();
  @Output() public hasFocusInput = new EventEmitter<Boolean>();
  @Output() public timeOutPut = new EventEmitter<any>();

  private static readonly DATE_FORMAT = 'yyyy-MM-dd';
  currentDate = new Date();
  lastMinute: number;
  lastHour: number;

  date;
  startDate: any;
  selectedMonth: number;
  readonly FORMAT_TIME = 'HH:mm';
  config: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    minutesInterval: 10,
    hours24Format: 'HH'
  };
  commonObject: Common;

  constructor(private cdr: ChangeDetectorRef, private commonService: CommonService) {
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit() {
    if (!this.isTicketEditor) {
      this.currentDate = this.currentDateTime ? this.currentDateTime : new Date();
      this.date = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
      this.currentDateStruct = this.date;
      this.startDate = this.date;
    } else {
      this.currentDate = this.currentDateTime ? this.currentDateTime : null;
      if (this.currentDate) {
        this.date = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        this.currentDateStruct = this.date;
        this.startDate = this.date;
        this.selectedMonth = this.currentDate.getMonth() + 1;
      } else {
        this.selectedMonth = new Date().getMonth() + 1;
      }
    }
    this.dateDisplay = this.currentDateTime
      ? this.formatDate
        ? DateTime.fromObject(this.date).toFormat(this.formatDate)
        : DateTime.fromObject(this.date).toFormat('yyyy.MM.dd')
      : '';
    const tmpTime = moment(this.time)
      .format(Constant.FORMAT_TIME_TO_MINUTES)
      .split(':');
    this.lastHour = parseInt(tmpTime[0]);
    this.lastMinute = parseInt(tmpTime[1]);
    if (this.isTicketManager) {
      if (!this.listDate || !this.listDate.length) {
        this.listDate = [];
        this.dateDisplay = '';
        this.currentDate = null;
        this.date = null;
        this.startDate = null;
        this.currentDateStruct = null;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isClear']) {
      if (this.isClear) {
        this.dateDisplay = '';
        this.currentDateChange.emit('');
        this.currentDate = null;
        this.date = new Date();
        this.startDate = this.date;
        this.currentDateStruct = null;
      }
    }
    if (changes['isClearTime']) {
      if (this.isClearTime) {
        this.time = '00:00';
        this.lastHour = 0;
        this.lastMinute = 0;
        this.timeOutPut.emit(this.time);
      }
    }
    if (changes['listDate']) {
      if (this.isTicketManager) {
        if (!this.listDate || !this.listDate.length) {
          this.listDate = [];
          this.dateDisplay = '';
          this.currentDateChange.emit('');
          this.currentDate = null;
          this.date = null;
          this.startDate = null;
          this.currentDateStruct = null;
          return;
        }
        this.currentDate = new Date(this.listDate[0]);
        this.startDate = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
      }
    }
    if (changes['currentDateTime']) {
      if (this.isTicketManager) {
        if (!this.listDate || !this.listDate.length) {
          this.listDate = [];
          this.dateDisplay = '';
          this.currentDateChange.emit('');
          this.currentDate = null;
          this.date = null;
          this.startDate = null;
          this.currentDateStruct = null;
          return;
        }
        this.currentDate = this.currentDateTime ? this.currentDateTime : new Date();
        this.date = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        this.startDate = this.date;
        this.currentDateStruct = this.date;
        let dateTime = DateTime.fromObject(this.currentDateStruct);
        if (this.formatDate) {
          this.dateDisplay = dateTime.toFormat(this.formatDate);
        } else {
          this.dateDisplay = dateTime.toFormat('yyyy.MM.dd');
        }
      }
    }
  }

  /**
   * choose a date value
   * @param date chosen date struct
   */
  chooseDate(date) {
    let isDisable: boolean = false;
    if (this.isTicketManager) {
      if (!this.listDate || !this.listDate.length) {
        return;
      }
      const ngbDateStructList: NgbDateStruct[] = this.listDate.map(this.convertToNgbDateStruct);
      if (date.month == this.selectedMonth) {
        isDisable = !ngbDateStructList.some(e => {
          return e.day === date.day && e.month === date.month && e.year === date.year;
        });
      }
    }
    if (!isDisable) {
      this.currentDateStruct = date;
      this.date = this.currentDateStruct;
      this.startDate = this.date;
      // this.currentDateTime = DateTime(this.currentDateTime as string).format('MMM. DD, YYYY');
      let dateTime = DateTime.fromObject(this.currentDateStruct);
      if (this.formatDate) {
        this.dateDisplay = dateTime.toFormat(this.formatDate);
      } else {
        this.dateDisplay = dateTime.toFormat('yyyy.MM.dd');
      }
      this.currentDateChange.emit(dateTime.toFormat(CustomDatePickerComponent.DATE_FORMAT));
    }
  }

  /**
   * Check Focus Input
   */
  checkFocusInput(): void {
    this.hasFocusInput.emit(true);
  }

  testTime(data) {
    if (this.isClearTime) {
      this.isClearTime = false;
      return;
    }
    let tmpTime =
      typeof data == 'object'
        ? moment(data._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : data.split(':');
    let currentMinute = parseInt(tmpTime[1]);
    let currentHour = parseInt(tmpTime[0]);
    let newHour, newMinute;
    if (
      (currentHour < this.lastHour && currentMinute > this.lastMinute) ||
      (currentHour > this.lastHour && currentMinute < this.lastMinute) ||
      (currentHour == 23 && currentMinute == 50 && this.lastMinute == 0 && this.lastHour == 0) ||
      (currentHour == 0 && currentMinute == 0 && this.lastMinute == 50 && this.lastHour == 23)
    ) {
      newHour = this.lastHour;
      newMinute = currentMinute;
      this.time = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      this.timeOutPut.emit(this.time);
      this.cdr.detectChanges();
    } else {
      this.lastHour = currentHour;
      this.lastMinute = currentMinute;
      this.time = data;
      if (typeof this.time != 'string') {
        this.time = moment(this.time).format(Constant.FORMAT_TIME_TO_MINUTES);
      }
      this.timeOutPut.emit(this.time);
    }
  }

  //Sử dụng cho ticket editor
  // Phương thức để xác định xem một ngày có trong mảng dates hay không
  isDateSelected(event: NgbDate): boolean {
    if (!this.isTicketEditor || !event) {
      return;
    }
    if (!this.date) {
      return false;
    }
    return event.year == this.date.year && event.month == this.date.month && event.day == this.date.day;
  }

  /**
   * isDifferentMonth
   * @param date
   * @returns
   */
  isDifferentMonth(date: NgbDateStruct): boolean {
    if (!this.isTicketEditor || !date) {
      return;
    }
    return date.month !== this.selectedMonth;
  }

  /**
   * onNavigate
   * @param event
   * @returns
   */
  onNavigate(event: NavigationEvent) {
    if (!this.isTicketEditor || !event) {
      return;
    }
    this.selectedMonth = event['next'].month;
  }

  /**
   * isDifferentDate
   * @param date
   * @returns
   */
  isDifferentDate(date: NgbDateStruct): boolean {
    if (!this.isTicketEditor || !date || !this.isTicketManager) {
      return;
    }
    if (this.isTicketManager && (!this.listDate || !this.listDate.length) && date.month == this.selectedMonth) {
      return true;
    }
    let isDisable: boolean;
    const ngbDateStructList: NgbDateStruct[] = this.listDate.map(this.convertToNgbDateStruct);
    if (date.month == this.selectedMonth) {
      isDisable = !ngbDateStructList.some(e => {
        return e.day === date.day && e.month === date.month && e.year === date.year;
      });
    }
    return isDisable;
  }

  /**
   * convertToNgbDateStruct
   * @param dateTimeString
   * @returns
   */
  convertToNgbDateStruct(dateTimeString: string): NgbDateStruct {
    const date = new Date(dateTimeString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }
}
